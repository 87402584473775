import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DSExample = makeShortcode("DSExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Bruk av tilbakemelding`}</h2>
    <p>{`Komponenten består av en tekst og tommel opp/ned-knapper som ved klikk viser et tilbakemeldingsskjema.`}</p>
    <p>{`Språket kan endres ved å sende inn `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`locale`}</code>{`-prop. Valgene er:`}</p>
    <ul>
      <li parentName="ul">{`Norsk bokmål: "nb"`}</li>
      <li parentName="ul">{`Nynorsk: "nn"`}</li>
      <li parentName="ul">{`Engelsk: "en"`}</li>
    </ul>
    <DSExample name="feedback_Feedback" mdxType="DSExample" />
    <h2>{`Farger og layout`}</h2>
    <p>{`Du kan velge bakgrunnsfarge ved å sende med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bgColor`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bgDarkmodeColor`}</code>{`. `}</p>
    <DSExample name="feedback_Feedback_in_custom_color" mdxType="DSExample" />
    <p>{`Gyldige valg for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bgColor`}</code>{` er:`}</p>
    <ul>
      <li parentName="ul">{`hvit`}</li>
      <li parentName="ul">{`frost-30`}</li>
      <li parentName="ul">{`sand-30`}</li>
      <li parentName="ul">{`syrin-30`}</li>
      <li parentName="ul">{`vann-30`}</li>
    </ul>
    <p>{`Gyldige valg for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bgDarkModeColor`}</code>{` er:`}</p>
    <ul>
      <li parentName="ul">{`svart`}</li>
      <li parentName="ul">{`natt`}</li>
    </ul>
    <p>{`Komponenten kan også brukes i kombinasjon med bølgen`}</p>
    <DSExample name="feedback_Feedback_in_wave" mdxType="DSExample" />
    <h2>{`contactLink`}</h2>
    <p>{`Du kan endre lenken i teksten ved å sende med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`contactLink`}</code>{`-prop og element som består av `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`url`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`linkText`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onClick`}</code>{`. `}</p>
    <p>{`Ved å bare sende inn `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onClick`}</code>{` kan du overskrive hva som skjer når lenken blir trykket på.`}</p>
    <DSExample name="feedback_Feedback_with_default_link" mdxType="DSExample" />
    <p>{`Hvis du vil sende med din egen tekst kan du legge til `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`linkText`}</code>{`:`}</p>
    <DSExample name="feedback_Feedback_with_custom_link" mdxType="DSExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      